<template>
  <div>
    <a-steps initial="0" :current="current">
        <a-step key="1"  title="User Details">
            <em slot="icon" class="icon ni ni-account-setting-fill"></em>
        </a-step>
        <a-step key="2"  title="Employee Details" v-if="isEmployee">
            <em slot="icon" class="icon ni ni-user-list-fill"></em>
        </a-step>
        <a-step key="3"   title="Job Details" v-if="isEmployee">
            <em slot="icon" class="icon ni ni-building"></em>
        </a-step>
        <a-step key="4"   title="Holiday / Hours" v-if="isEmployee">
            <em slot="icon" class="icon ni ni-calendar-fill"></em>
        </a-step>
    </a-steps>
    <div class="row p-4">
        <div class="col-md-12">
            <div v-if="current == 1" class="card-bordered">
                <div class="card-inner">
                    <div v-if="employee.user">
                        <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Primary Role</label>
                            </div>
                            <div class="col-4">
                                <a-select class="invisibox" v-model="employee.user.primaryRoleId" style="width: 100%">
                                    <a-select-option v-for="role in userRoles" :key="role.id">
                                    {{ role.name }}
                                    </a-select-option>
                                </a-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">First Name</label>
                            </div>
                            <div class="col-4">
                                <a-input class="invisibox" v-model="employee.user.firstName" :class="{'is-error' : $v.employee.user.firstName.$invalid }" placeholder="First Name" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Last Name</label>
                            </div>
                            <div class="col-4">
                                <a-input class="invisibox" v-model="employee.user.lastName" :class="{'is-error' : $v.employee.user.lastName.$invalid }" placeholder="Last Name" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Email Address</label>
                            </div>
                            <div class="col-4">
                                <a-input class="invisibox" @change="emailChanged" v-model="employee.user.email" :class="{'is-error' : $v.employee.user.email.$invalid }" placeholder="Email" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right" style="margin-top: 0px">Send Welcome Email</label>
                            </div>
                            <div class="col-4">
                                <a-checkbox  :disabled="!employee.user.email" v-model="employee.user.sendUserWelcomeEmail">Email The User Login Details</a-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right" style="margin-top: 0px">Location Enabled</label>
                            </div>
                            <div class="col-4">
                                <a-switch class="mb-1" @change="toggleUserLocation()" checked-children="Location Enabled" un-checked-children="Location Disabled" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right" style="margin-top: 0px">User is an Employee</label>
                            </div>
                            <div class="col-4">
                                <a-switch @change="isEmployeeChange()" default-checked>
                                    <a-icon slot="checkedChildren" type="check" />
                                    <a-icon slot="unCheckedChildren" type="close" />
                                </a-switch>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            <div v-if="current == 2" class="card card-bordered">
                <div class="card-inner">
                    <div v-if="employee.employeeDetails">
                        <a-form-model ref="user" :model="employee.employeeDetails">
                            <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Gender</label>
                            </div>
                            <div class="col-4">
                                <a-select class="invisibox" v-model="employee.employeeDetails.genderId" placeholder="Select Gender" >
                                    <a-select-option v-for="g in genders" :key="g.id">
                                    {{ g.text }}
                                    </a-select-option>
                                </a-select>
                            </div>
                            </div>

                            <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Date Of Birth</label>
                            </div>
                            <div class="col-4">
                                <a-date-picker class="invisibox" v-model="employee.employeeDetails.dateOfBirth" placeholder="Date Of Birth" format="DD/MM/YYYY" />
                            </div>
                            </div>

                            <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Phone</label>
                            </div>
                            <div class="col-4">
                                <a-input class="invisibox" v-model="employee.employeeDetails.telephoneNumber" placeholder="Phone Number" />
                            </div>
                            </div>

                            <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Line 1</label>
                            </div>
                            <div class="col-4">
                                <a-input class="invisibox" v-model="employee.employeeDetails.addressLineOne" placeholder="Line One" />
                            </div>
                            </div>

                            <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Line 2</label>
                            </div>
                            <div class="col-4">
                                <a-input class="invisibox" v-model="employee.employeeDetails.addressLineTwo" placeholder="Line Two" />
                            </div>
                            </div>

                            <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Town / City</label>
                            </div>
                            <div class="col-4">
                                <a-input class="invisibox" v-model="employee.employeeDetails.townOrCity" placeholder="Town Or City" />
                            </div>
                            </div>

                            <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">County / State</label>
                            </div>
                            <div class="col-4">
                                <a-input class="invisibox" v-model="employee.employeeDetails.countyOrState" placeholder="County Or State" />
                            </div>
                            </div>

                            <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Country</label>
                            </div>
                            <div class="col-4">
                            <a-input class="invisibox" v-model="employee.employeeDetails.country" placeholder="Country" />
                            </div>
                            </div>

                            <div class="row">
                            <div class="col-5">
                                <label class="form-label float-right">Postcode</label>
                            </div>
                            <div class="col-4">
                                <a-input class="invisibox" v-model="employee.employeeDetails.postCode" placeholder="Postcode" />
                            </div>
                            </div>
                        </a-form-model>
                        <!-- <a-button class="btn-success" block html-type="submit" @click="saveEmployeeDetails()">
                            <em class="icon ni ni-plus" style="fontSize:12px; padding:5px; padding-top:6px" /> Save Details
                        </a-button> -->
                    </div>
                </div>
            </div>
            <div v-if="current == 3" class="card card-bordered">
                <div class="card-inner">
                    <div v-if="employee.employeeJobDetails">
                        <a-form-model ref="user" :model="employee.employeeJobDetails">
                    <div class="row">
                        <div class="col-5">
                        <label class="form-label float-right">Location</label>
                        </div>
                        <div class="col-4">
                        <a-input class="invisibox" v-model="employee.employeeJobDetails.location" placeholder="Location" />
                        </div>
                    </div>                   

                    <div class="row">
                        <div class="col-5">
                        <label class="form-label float-right">Department</label>
                        </div>
                        <div class="col-4">
                        <a-select class="invisibox" v-model="employee.employeeJobDetails.departmentId" placeholder="Select Department">
                            <a-select-option key="1">
                                Test
                            </a-select-option>
                            </a-select>
                        </div>
                    </div>     
                                                    
                    <div class="row">
                        <div class="col-5">
                        <label class="form-label float-right">Job Role</label>
                        </div>
                        <div class="col-4">
                        <a-input class="invisibox" v-model="employee.employeeJobDetails.jobRole" placeholder="Job Role" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-5">
                        <label class="form-label float-right">Reports To</label>
                        </div>
                        <div class="col-4">
                        <a-select class="invisibox" v-model="employee.employeeJobDetails.reportsToId" placeholder="Select User">
                            <a-select-option v-for="u in users" :key="u.id">
                                {{ u.firstName + ' ' + u.lastName }}
                            </a-select-option>
                        </a-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-5">
                        <label class="form-label float-right">Start Date</label>
                        </div>
                        <div class="col-4">
                        <a-date-picker class="invisibox" v-model="employee.employeeJobDetails.startDate" :class="{'is-error' : $v.employee.employeeJobDetails.startDate.$invalid }" placeholder="Start Date" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-5">
                        <label class="form-label float-right">NI / SSN Number</label>
                        </div>
                        <div class="col-4">
                        <a-input class="invisibox" v-model="employee.employeeJobDetails.niNumberOrSSN" placeholder="NI / SSN Number" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-5">
                        <label class="form-label float-right">Holiday Year</label>
                        </div>
                        <div class="col-4">
                        <a-select class="invisibox" v-model="employee.employeeJobDetails.holidayYearId" :class="{'is-error' : $v.employee.employeeJobDetails.holidayYearId.$invalid }" placeholder="Select Holiday Year">
                            <a-select-option v-for="h in holidayYears" :key="h.id">
                                {{ h.text }}
                            </a-select-option>
                        </a-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-5">
                        <label class="form-label float-right">Work Pattern</label>
                        </div>
                        <div class="col-4">
                        <a-select class="invisibox" v-model="employee.employeeJobDetails.workPatternId" :class="{'is-error' : $v.employee.employeeJobDetails.workPatternId.$invalid }" placeholder="Select Work Pattern">
                            <a-select-option v-for="w in workPatterns" :key="w.id">
                                {{ w.text }}
                            </a-select-option>
                        </a-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-5">
                        <label class="form-label float-right">Salary Period</label>
                        </div>
                        <div class="col-4">
                        <a-select class="invisibox" v-model="employee.employeeJobDetails.salaryPeriodId" placeholder="Select Salary Period">
                            <a-select-option v-for="s in salaryPeriods" :key="s.id">
                                {{ s.text }}
                            </a-select-option>
                        </a-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-5">
                        <label class="form-label float-right">Salary Amount</label>
                        </div>
                        <div class="col-4">
                        <a-input class="invisibox" v-model="employee.employeeJobDetails.salaryAmount" placeholder="0.00" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-5">
                        <label class="form-label float-right">Salary Currency</label>
                        </div>
                        <div class="col-4">
                        <a-select class="invisibox" v-model="employee.employeeJobDetails.salaryCurrencyId" placeholder="Select Currency">
                            <a-select-option v-for="c in currencies" :key="c.id">
                                {{ c.text }}
                            </a-select-option>
                        </a-select>
                        </div>
                    </div>                                                        
                    </a-form-model>
                    </div>
                </div>
            </div>
            <div v-if="current == 4" class="card card-bordered">
                <div class="card-inner">
                    <div v-if="employee.employeeAnnualLeaveAllowance">
                        <a-form-model ref="annualLeave" :model="employeeAnnualLeaveAllowance" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="durationMeasurementId">Annual Leave Measurement</label>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-select v-model="employee.employeeAnnualLeaveAllowance.durationMeasurementId" :class="{'is-error' : $v.employee.employeeAnnualLeaveAllowance.durationMeasurementId.$invalid }" type="text" class="invisibox" id="durationMeasurementId" placeholder="Select Annual Leave Measurement">
                                                <a-select-option v-for="a in durationMeasurements" :key="a.id">
                                                    {{ a.text }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- <a-form-model-item
                                prop="durationMeasurementId"
                                ref="durationMeasurementId"
                                :rules="{
                                required: false,
                                message: 'Please enter the users full name',
                                trigger: 'blur',
                                }"
                                label="Location">
                                <a-input v-model="annualLeave.durationMeasurementId" placeholder="John Smith" />
                            </a-form-model-item>                             -->
                                    
                            <div class="row g-3 align-center">
                                    <div class="col-lg-5">
                                        <div class="form-group">
                                            <label class="form-label float-right" for="contractedHours">Contracted Hours</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <div class="form-control-wrap">
                                            <a-input placeholder="-" v-model="employee.employeeAnnualLeaveAllowance.contractedHours" :class="{'is-error' : $v.employee.employeeAnnualLeaveAllowance.contractedHours.$invalid }" type="text" class="invisibox" id="contractedHours"/>
                                            </div>
                                        </div>
                                    </div>
                            </div>                                     
                            <div class="row g-3 align-center">
                                    <div class="col-lg-5">
                                        <div class="form-group">
                                            <label class="form-label float-right" for="fullTimeHours">Full-Time Hours</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <div class="form-control-wrap">
                                            <a-input placeholder="-" v-model="employee.employeeAnnualLeaveAllowance.fullTimeHours" :class="{'is-error' : $v.employee.employeeAnnualLeaveAllowance.fullTimeHours.$invalid }" type="text" class="invisibox" id="fullTimeHours"/>
                                            </div>
                                        </div>
                                    </div>
                            </div>                              
                            <div class="row g-3 align-center">
                                    <div class="col-lg-5">
                                        <div class="form-group">
                                            <label class="form-label float-right" for="fullTimeEntitlement">Full-Time Entitlement</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <div class="form-control-wrap">
                                            <a-input placeholder="-" class="invisibox" v-model="employee.employeeAnnualLeaveAllowance.fullTimeEntitlement" :class="{'is-error' : $v.employee.employeeAnnualLeaveAllowance.fullTimeEntitlement.$invalid }" id="fullTimeEntitlement"/>
                                            </div>
                                        </div>
                                    </div>
                            </div>                                   
                            <div class="row g-3 align-center">
                                    <div class="col-lg-5">
                                        <div class="form-group">
                                            <label class="form-label float-right" for="entitlementIncludesPublicHolidays">Entitlement Includes Public Holidays</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <div class="form-control-wrap">
                                            <a-checkbox v-model="employee.employeeAnnualLeaveAllowance.entitlementIncludesPublicHolidays" id="entitlementIncludesPublicHolidays"/>
                                            </div>
                                        </div>
                                    </div>
                            </div>                                
                            <div class="row g-3 align-center">
                                    <div class="col-lg-5">
                                        <div class="form-group">
                                            <label class="form-label float-right" for="allowExceedAllowance">Allow Exceed Allowance</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <div class="form-control-wrap">
                                            <a-checkbox v-model="employee.employeeAnnualLeaveAllowance.allowExceedAllowance" id="allowExceedAllowance"/>
                                            </div>
                                        </div>
                                    </div>
                            </div>                            
                        </a-form-model>
                    </div>
                </div>
            </div> 
        </div>
    </div>
    <div class="row p-4">
        <div class="col-md-12">
            <a-button v-if="current >= 2 & isEmployee" style="margin-left: 8px;"  @click="changePrevious()">
                Previous
            </a-button>
            <a-button v-if="current < 4 & isEmployee" type="primary" class="float-right btn-dark" style="color: #fff;" @click="changeNext()">
                Next
            </a-button>
            <a-button v-if="current == 4 || isEmployee == false" type="primary" class="float-right btn-success" style="color: #fff;" @click="save()">
                Save
            </a-button>
        </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
    validations: {
        employee: {
            user: {
                firstName: {
                    required
                },
                lastName: {
                    required
                },
                email: {
                    required
                }
            },
            employeeJobDetails: {
                startDate: {
                    required
                },
                holidayYearId: {
                    required
                },
                workPatternId: {
                    required
                }
            },
            employeeAnnualLeaveAllowance: {
                durationMeasurementId: {
                    required
                },
                contractedHours: {
                    required
                },
                fullTimeHours: {
                    required
                },
                fullTimeEntitlement: {
                    required
                }
            },
        }
    },
    props: {
        tenantId: { type: String }
    },
    data() {
        return {
            current: 1,
            isEmployee: true,
            user: {},
            employeeDetails: {},
            employeeJobDetails: {},
            annualLeave: {},
            genders: null,
            users: null,
            notEmployeeUsers: null,
            durationMeasurements: null,
            workPatterns: null,
            currencies: null,
            salaryPeriods: null,
            userRoles: null,
            employee: {
                employeeDetails: {},
                employeeJobDetails: {},
                employeeAnnualLeaveAllowance: {},
                user: {},
            },
        }
    },
    created() {
        this.getLists()
    },
    methods: {
        isEmployeeChange() {
            if (this.isEmployee == false) {
                this.isEmployee = true
            } else {
                this.isEmployee = false
            }
        },
        getLists() {
            this.$http.get('/users/Get_Users')
            .then((response) => {
                this.users = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/genders/')
            .then((response) => {
                this.genders = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.get('/users/get_UserNotEmployees')
            .then((response) => {
                this.notEmployeeUsers = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/durationMeasurements')
            .then((response) => {
                this.durationMeasurements = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/workPatterns')
            .then((response) => {
                this.workPatterns = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/currencies')
            .then((response) => {
                this.currencies = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/salaryPeriods')
            .then((response) => {
                this.salaryPeriods = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/holidayYears')
            .then((response) => {
                this.holidayYears = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.post('/lists/Get_AvailablePrimaryUserRoles/', this.user)
            .then((response) => {
                this.userRoles = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        changeNext() {
            this.current += 1
        },
        changePrevious() {
            this.current -= 1
        },
        saveUser(){
            this.employee.user.tenantId = this.tenantId
            this.$http.post('/users/Add_User/', this.employee.user)
            .then(() => {
                this.$message.success('User Added')
                this.$emit('userAdded')
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        saveEmployee(){
            this.$http.post('/users/Add_Employee_Wizard/', this.employee)
            .then(() => {
                this.$message.success('User Added')
                this.$emit('userAdded')
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        save() {
            if (this.isEmployee == true) {
                if (this.$v.employee.$invalid) {
                    this.$message.error('Please check for any form errors.')
                } else {
                    this.saveEmployee()
                }
            } else {
                if (this.$v.employee.user.$invalid) {
                    this.$message.error('Please check for any form errors.')
                } else {
                    this.saveUser()
                }
            }
        }
    }
}
</script>

<style scoped>
.form-label {
    margin-top: 6px;
}
</style>